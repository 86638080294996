var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "prof", attrs: { wrap: "", "pt-3": "", "gap-xs-3": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                [
                  _vm.certificates && _vm.certificates.length
                    ? _vm._l(_vm.certificates, function(certificate) {
                        return _c(
                          "v-layout",
                          { key: certificate.id, attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              [
                                _c("profile-certificate", {
                                  attrs: { certificate: certificate }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    : [
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "app.profile.my_starling.certificates.subtitle"
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }